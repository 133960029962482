.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0;
}
.brand_name{
    color: #e9f1fa;
}
.search_icon{
    background-color: rgba(254,158,68,0.75);
    color: #fff;
    width: 37px;
    height: 37px;
    text-align: center;
    line-height: 37px;
    border-radius: 50%;
    display: inline-block;
}

.logo{
    object-fit: contain;
    object-position: center center;
    width: 30%;
    cursor:pointer;
    /* height: 100%; */
}